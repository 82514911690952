export default [
  {
    path: '/report/customer',
    name: 'customer-report',
    component: () => import('@/views/epass/report/customer/ReportList.vue'),
    meta: {
      action: 'read',
      resource: 'RedeemReport',
    },
  },
  // {
  //   path: '/report/monitor',
  //   name: 'monitor-report',
  //   component: () => import('@/views/epass/customer/customer-list/CustomersList.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/report/delivery',
    name: 'delivery-report',
    component: () => import('@/views/epass/report/order/ReportList.vue'),
    meta: {
      action: 'read',
      resource: 'OrderReport',
    },
  },
  {
    path: '/report/log',
    name: 'log-report',
    component: () => import('@/views/epass/report/log/ReportList.vue'),
    meta: {
      action: 'read',
      resource: 'LogReport',
    },
  },
]
