export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/epass/user/users-list/UsersList.vue'),
    meta: {
      resource: 'AuthUser',
      action: 'all',
    },
  },
  // {
  //   path: '/users/view/:id',
  //   name: 'users-view',
  //   component: () => import('@/views/epass/user/users-view/UsersView.vue'),
  // },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/epass/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'AuthUser',
      action: 'all',
    },
  },

]
