import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // For Pentest
  baseURL: 'https://yy1w6vi45l.execute-api.ap-southeast-1.amazonaws.com/api/',
  // baseURL: 'http://localhost:8088/',
  timeout: 11000,
  headers: { 'x-api-key': 'SIs2U8pfWW6ppLWPrS6l7nPhhttcWo26Q6TQxTs3' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
