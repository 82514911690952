export default [
  {
    path: '/customer/list',
    name: 'customer-list',
    component: () => import('@/views/epass/customer/customer-list/CustomersList.vue'),
    meta: {
      resource: 'Customer',
      action: 'read',
    },
  },
  {
    path: '/customer/edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/epass/customer/customer-edit/CustomerEdit.vue'),
    meta: {
      resource: 'Customer',
      action: 'read',
      navActiveLink: 'customer-list',
    },
  },
]
